<!-- 物业新增 -->
<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm0" class="el-form-wrap">
        <ykc-form-item label="物业编号" prop="propertyNo" v-if="isEdit">
          <ykc-input
            placeholder="请输入物业编号"
            v-model="ruleForm.propertyNo"
            disabled></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="物业名称" prop="propertyName">
          <ykc-input
            placeholder="请输入物业名称"
            maxlength="50"
            v-model="ruleForm.propertyName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="物业类型" prop="propertyType">
          <ykc-radio
            :data="[
              { id: 1, name: '一级物业' },
              { id: 2, name: '二级物业' },
            ]"
            v-model="ruleForm.propertyType"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item v-if="ruleForm.propertyType === 2" label="所属一级物业" prop="parentId">
          <ykc-dropdown
            placeholder="请选择所属一级物业"
            :data="parentIdData"
            v-model="ruleForm.parentId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="物业地址" prop="propertyAddress">
          <ykc-input
            placeholder="请输入物业地址"
            maxlength="50"
            v-model="ruleForm.propertyAddress"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="资质名称" prop="aptitudeName">
          <ykc-input
            placeholder="请输入资质名称"
            maxlength="50"
            v-model="ruleForm.aptitudeName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="商业模式" prop="businessMode">
          <ykc-dropdown
            placeholder="请选择商业模式"
            :data="businessModeData"
            v-model="ruleForm.businessMode"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="联系⼈姓名" prop="contacts">
          <ykc-input
            placeholder="请输入联系⼈"
            maxlength="50"
            v-model="ruleForm.contacts"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="联系人性别" prop="contactsSex">
          <ykc-dropdown
            placeholder="请选择联系人性别"
            :data="contactsSexData"
            v-model="ruleForm.contactsSex"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="联系人手机" prop="contactsPhone">
          <ykc-input
            placeholder="请输入联系人手机"
            maxlength="11"
            v-model="ruleForm.contactsPhone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="联系人职务" prop="contactsJob">
          <ykc-input
            placeholder="请输入联系人职务"
            maxlength="50"
            v-model="ruleForm.contactsJob"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="联系人邮箱" prop="contactsEmail">
          <ykc-input
            placeholder="请输入联系人邮箱"
            maxlength="50"
            v-model="ruleForm.contactsEmail"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="联系人电话" prop="contactsTelephone">
          <ykc-input
            placeholder="请输入联系人电话"
            maxlength="11"
            v-model="ruleForm.contactsTelephone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="收款单位名称" prop="company">
          <ykc-input
            placeholder="请输入收款单位名称"
            maxlength="50"
            v-model="ruleForm.company"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="收款银⾏" prop="bank">
          <ykc-input
            placeholder="请输入收款银⾏"
            maxlength="50"
            v-model="ruleForm.bank"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          label="收款账号"
          prop="bankNo"
          bottomTip="特别提醒:请再三确认收款银⾏账户信息，否则造成的⼀切经济纠纷与责任由贵司承担">
          <ykc-input
            placeholder="请输入收款账号"
            maxlength="50"
            v-model="ruleForm.bankNo"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          label="订单金额是否给物业"
          prop="orderTo"
          bottomTip="该字段会影响物业结算及⽤户订单⾦额归属问题，请谨慎选择">
          <ykc-radio
            :disabled="isProperty"
            :data="[
              { id: 0, name: '否' },
              { id: 1, name: '是' },
            ]"
            v-model="ruleForm.orderTo"></ykc-radio>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { propertymentData } from '@/service/apis';
  import { sessionGetItem } from '../../../../utils';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {}, // 详情数据
        dictionary: {},
        organizations: [],
        isProperty: false,
        pictureArr: [
          {
            name: '上传图片',
            url: '',
          },
        ],
        businessModeData: [
          { id: 1, name: '物业' },
          { id: 2, name: '大客户' },
        ],
        contactsSexData: [
          { id: 1, name: '男' },
          { id: 2, name: '女' },
        ],
        ruleForm: {
          // operatorType: '2', // 互联商户
          id: '',
          aptitudeName: '',
          businessMode: '',
          contacts: '',
          contactsEmail: '',
          contactsJob: '',
          contactsPhone: '',
          contactsSex: '',
          contactsTelephone: '',
          propertyAddress: '',
          parentId: '',
          propertyType: 1,
          propertyName: '',
          propertyNo: '',
          orderTo: 0,
        },
        rules: {
          propertyName: [
            { required: true, message: '请输入物业名称', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.nameType.regexp,
            //   message: regexpObj.regexp.input.nameType.errorTips.error('物业名称'),
            // },
          ],
          propertyType: [
            { required: true, message: '请选择物业类型', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.nameType.regexp,
            //   message: regexpObj.regexp.input.nameType.errorTips.error('物业名称'),
            // },
          ],
          parentId: [
            { required: true, message: '请选择所属一级物业', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.nameType.regexp,
            //   message: regexpObj.regexp.input.nameType.errorTips.error('物业名称'),
            // },
          ],
        },
      };
    },
    mounted() {
      this.isProperty = JSON.parse(sessionGetItem('propertyFlag'));
      this.getOneLevelPropertyInfo(this.id);
      if (this.id) {
        propertymentData
          .propertymentDetail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
              businessMode: res.businessMode,
              contactsSex: res.contactsSex,
            };
          })
          .catch(() => {});
      } else {
        // this.$refs.ruleForm0.resetFields();
      }
    },
    computed: {
      formData() {
        const formData = {
          ...this.ruleForm,
          parentId: this.ruleForm.propertyType === 2 ? this.ruleForm.parentId : '',
          id: this.id, // 编辑需要传
        };
        return formData;
      },
    },
    methods: {
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              propertymentData
                .saveOrUpdate(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              // console.log('编辑', this.formData);
              propertymentData
                .saveOrUpdate(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /** 校验所有表格是否通过 */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
      getOneLevelPropertyInfo(id) {
        propertymentData
          .getOneLevelPropertyInfo({})
          .then(res => {
            this.parentIdData = (res || []).map(item => ({
              id: item.id,
              name: item.propertyName,
            }));
            if (id) {
              this.parentIdData = this.parentIdData.filter(item => item.id !== id);
            }
          })
          .catch(() => {});
      },
    },
  };
</script>
<style lang="scss" scoped>
  .upload-img-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
